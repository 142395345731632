body.about {
  nav.tagNav {
    display: none;
  }

  section.mainSection {
    padding: 35px 0 10px 0;
  }

  section.mainSection.aboutHeader {
    padding-top: 0;

    h2 {
      margin: 0;
      padding: 0;
      font-size: 8rem;
      font-family: $tin;
      font-weight: 400;
      color: $black;

      span.de {
        font-family: $ral;
        font-weight: 800;
      }
    }

    a.rightArrow {
      display: block;
      margin-top: 65px;
    }

    p.aboutIntro {
      font-family: $ral;
      font-weight: 300;
      font-size: 1.6rem;
    }
  }

  div.brandList {
    text-align: center;

    img {
      margin: 10px;
    }
  }

  nav.aboutNav {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        padding-bottom: 1.4em;

        a {
          font-family: $ral;
          font-size: 1.6rem;
          letter-spacing: 0.075em;
          font-weight: 700;
          color: $midgray;
          text-transform: uppercase;
        }

        a.active,
        a:hover {
          color: $black;
        }
      }
    }
  }

  p.aboutSection {
    background: url("/dist/img/about_arrow.png") no-repeat;
    background-position: 0 0;
    margin: 0 0 0 -25px;
    padding-left: 25px;
    font-family: $ral;
    font-weight: 300;
    font-size: 1.4rem;
  }
}

div.aboutLollo {
  h4,
  h5 {
    margin: 0;
    padding: 0;
    font-family: $ral;
    font-size: 1.6rem;
  }

  h4 {
    font-weight: 800;
    text-transform: uppercase;
  }

  h5 {
    font-weight: 400;
  }

  p {
    font-family: $tin;
    font-weight: 300;
    font-size: 1.6rem;
  }
}

#detail_0 {}

#detail_1 {
  margin-top: 4.2rem;
}

#detail_2 {
  margin-top: 8.3rem;
}

#detail_3 {
  margin-top: 12.4rem;
}

#detail_4 {
  margin-top: 16.5rem;
}

#detail_5 {
  margin-top: 20.6rem;
}
