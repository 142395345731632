/* grid etc */
html {
  font-size: 62.5%;
}

.container {
  width: 960px;
  margin: 0 auto;
}

body,
html {
  width: 100vw;
  background-color: $lightgray;
}

.pageHeader {
  background: $white;
  height: 130px;
}

.headerLogo {
  h1 {
    display: inline-block;

    a {
      display: block;
      width: 90px;
      height: 90px;
      text-indent: -9999px;
      background: url("/dist/img/de_logo.svg") no-repeat;
      background-size: cover;
    }
  }
}

h2.header {
  padding-top: 30px;
  display: inline-block;
  font-family: $ral;
  font-weight: 400;
  text-transform: uppercase;
}

a.menuButton {
  display: block;
  margin-top: 50px;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
  background: url("/dist/img/ico_menu.svg") no-repeat;
  background-size: cover;
  position: relative;
  right: -35px;
}

nav.socialNav,
nav.tagNav,
nav.topNav {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
      text-transform: uppercase;
      margin-right: 1em;

      a {
        font-family: $ral;
        font-weight: 400;
        color: $darkgray;
        font-size: 1.2rem;
      }
    }
  }
}

nav.socialNav,
nav.tagNav {
  ul {
    li {
      a {
        font-size: 1.1rem;
        color:$medgray;
      }

      a.active,
      a:hover {
        color: $darkred;
        text-decoration: line-through;
      }
    }
  }
}

nav.topNav {
  text-align: right;
  padding-top: 50px;

  a.active {
    color: $black;
    text-decoration: none;
    font-weight: 700;
  }
}

div.tagNavContainer {
  position: relative;
}

nav.tagNav {
  text-align: right;
  transform: rotate(-90deg) translate(0px,-290px);
  transform-origin: 100% 0;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 9999;
}

nav.socialNav {
  display: block;
  width: 50vh;
  text-align: left;
  transform: rotate(90deg) translate(0,-960px);
  transform-origin: 0 0;
  position: relative;
  top:44px;
}

section.mainSection {
  padding-top: 20px;

  h1 {
    margin: 0;
    font-family: $ral;
    color: $darkgray;
    font-weight: 200;
    font-size: 2.8rem;
    text-transform: uppercase;
  }
}

section.mainContent {
  min-height: 650px;
  padding-bottom: 20px;
  padding-top:35px;
}

$wh: 395px;

article.workItem {
  position: relative;
  width: 100%;
  height: 250px;

  div.workPicture {
    position: absolute;
    width: $wh;
    height: $wh;
    overflow: hidden;

    a.workPreviewLink {
      display: block;
      width: $wh;
      height: $wh;
    }

    img {
      width: $wh;
      height: $wh;
    }

    a.arrow {
      position: absolute;
      top: 10px;
      display: block;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-size: cover;
      width: 25px;
      height: 19px;
    }
  }

  div.workPicture.left {
    left: 0;

    a.arrow {
      left: 10px;
      background: url("/dist/img/freccia_dx.svg") no-repeat;
      background-size: cover;
    }
  }

  div.workPicture.right {
    right: 0;

    a.arrow {
      right: 10px;
      background: url("/dist/img/freccia_sx.svg") no-repeat;
      background-size: cover;
    }
  }

  a.workTitle {
    color: $white;

    h1 {
      color: $white;
      display: block;
      position: absolute;
      z-index: 9999;
      top: calc(85%-6.4rem);
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
      font-family: $tin;
      font-weight: 400;
      font-size: 6.4rem;
      text-transform: capitalize;
      // text-shadow: 1px 1px 5px rgba(0,0,0,.5);
    }

    h1.last {
      top: calc(55%-6.4rem);
    }
  }
}

article.workItem:last-child {
  height: 395px;

  h1 {
    top: calc(50%-5.4rem);
  }
}

section.mainSection.workHeader {
  position: relative;

  h1 {
    color: $white;
    display: block;
    position: absolute;
    top: 20px;
    left: 12.5%;
    z-index: 9999;
    width: 87.5%;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: $tin;
    font-weight: 400;
    font-size: 8rem;
    text-transform: capitalize;
    /*  text-shadow: 1px 1px 5px rgba(0,0,0,.5); */
  }

  h2 {
    margin: 0;
    padding: 0;
    margin-top: calc(9rem+20px);
    font-family: $tin;
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    font-size: 1.6rem;
  }

  h3.projectTags {
    margin: 1em 1em 0 0;
    padding: 0;
    font-family: $ral;
    font-weight: 200;
    font-size: 1.4rem;
    display: inline-block;

    a {
      color: $black;
    }
  }

  p.projectDescription {
    font-family: $ral;
    font-weight: 400;
    font-size: 1.4rem;
  }
}

section.mainSection.workPhotos {
  margin-top: 0;

  div.row {
    margin-top: 20px;
  }
}
