/*consts*/
/* RESET */
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,700,800|Tinos:400&display=swap");
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type=checkbox],
input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid silver;
}

legend {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

@media print {
  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

/**/
.clearfix:after,
.clearfix:before,
.container:after,
.container:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.clearfix:after,
.container:after,
.row:after {
  clear: both;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

strong {
  font-weight: 800;
}

/* GRID SYSTEM */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
  clear: both;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-13,
.col-lg-14,
.col-lg-15,
.col-lg-16,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-13,
.col-md-14,
.col-md-15,
.col-md-16,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-13,
.col-sm-14,
.col-sm-15,
.col-sm-16,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-13,
.col-xs-14,
.col-xs-15,
.col-xs-16,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-13,
.col-xs-14,
.col-xs-15,
.col-xs-16,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.col-xs-1 {
  width: 6.25%;
}

.col-xs-2 {
  width: 12.5%;
}

.col-xs-3 {
  width: 18.75%;
}

.col-xs-4 {
  width: 25%;
}

.col-xs-5 {
  width: 31.25%;
}

.col-xs-6 {
  width: 37.5%;
}

.col-xs-7 {
  width: 43.75%;
}

.col-xs-8 {
  width: 50%;
}

.col-xs-9 {
  width: 56.25%;
}

.col-xs-10 {
  width: 62.5%;
}

.col-xs-11 {
  width: 68.75%;
}

.col-xs-12 {
  width: 75%;
}

.col-xs-13 {
  width: 81.25%;
}

.col-xs-14 {
  width: 87.5%;
}

.col-xs-15 {
  width: 93.75%;
}

.col-xs-16 {
  width: 100%;
}

.col-xs-offset-1 {
  margin-left: 6.25%;
}

.col-xs-offset-2 {
  margin-left: 12.5%;
}

.col-xs-offset-3 {
  margin-left: 18.75%;
}

.col-xs-offset-4 {
  margin-left: 25%;
}

.col-xs-offset-5 {
  margin-left: 31.25%;
}

.col-xs-offset-6 {
  margin-left: 37.5%;
}

.col-xs-offset-7 {
  margin-left: 43.75%;
}

.col-xs-offset-8 {
  margin-left: 50%;
}

.col-xs-offset-9 {
  margin-left: 56.25%;
}

.col-xs-offset-10 {
  margin-left: 62.5%;
}

.col-xs-offset-11 {
  margin-left: 68.75%;
}

.col-xs-offset-12 {
  margin-left: 75%;
}

.col-xs-offset-13 {
  margin-left: 81.25%;
}

.col-xs-offset-14 {
  margin-left: 87.5%;
}

.col-xs-offset-15 {
  margin-left: 93.75%;
}

.col-xs-offset-16 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-13,
  .col-sm-14,
  .col-sm-15,
  .col-sm-16,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
  .col-sm-1 {
    width: 6.25%;
  }
  .col-sm-2 {
    width: 12.5%;
  }
  .col-sm-3 {
    width: 18.75%;
  }
  .col-sm-4 {
    width: 25%;
  }
  .col-sm-5 {
    width: 31.25%;
  }
  .col-sm-6 {
    width: 37.5%;
  }
  .col-sm-7 {
    width: 43.75%;
  }
  .col-sm-8 {
    width: 50%;
  }
  .col-sm-9 {
    width: 56.25%;
  }
  .col-sm-10 {
    width: 62.5%;
  }
  .col-sm-11 {
    width: 68.75%;
  }
  .col-sm-12 {
    width: 75%;
  }
  .col-sm-13 {
    width: 81.25%;
  }
  .col-sm-14 {
    width: 87.5%;
  }
  .col-sm-15 {
    width: 93.75%;
  }
  .col-sm-16 {
    width: 100%;
  }
  .col-sm-offset-1 {
    margin-left: 6.25%;
  }
  .col-sm-offset-2 {
    margin-left: 12.5%;
  }
  .col-sm-offset-3 {
    margin-left: 18.75%;
  }
  .col-sm-offset-4 {
    margin-left: 25%;
  }
  .col-sm-offset-5 {
    margin-left: 31.25%;
  }
  .col-sm-offset-6 {
    margin-left: 37.5%;
  }
  .col-sm-offset-7 {
    margin-left: 43.75%;
  }
  .col-sm-offset-8 {
    margin-left: 50%;
  }
  .col-sm-offset-9 {
    margin-left: 56.25%;
  }
  .col-sm-offset-10 {
    margin-left: 62.5%;
  }
  .col-sm-offset-11 {
    margin-left: 68.75%;
  }
  .col-sm-offset-12 {
    margin-left: 75%;
  }
  .col-sm-offset-13 {
    margin-left: 81.25%;
  }
  .col-sm-offset-14 {
    margin-left: 87.5%;
  }
  .col-sm-offset-15 {
    margin-left: 93.75%;
  }
  .col-sm-offset-16 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-13,
  .col-md-14,
  .col-md-15,
  .col-md-16,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
  .col-md-1 {
    width: 6.25%;
  }
  .col-md-2 {
    width: 12.5%;
  }
  .col-md-3 {
    width: 18.75%;
  }
  .col-md-4 {
    width: 25%;
  }
  .col-md-5 {
    width: 31.25%;
  }
  .col-md-6 {
    width: 37.5%;
  }
  .col-md-7 {
    width: 43.75%;
  }
  .col-md-8 {
    width: 50%;
  }
  .col-md-9 {
    width: 56.25%;
  }
  .col-md-10 {
    width: 62.5%;
  }
  .col-md-11 {
    width: 68.75%;
  }
  .col-md-12 {
    width: 75%;
  }
  .col-md-13 {
    width: 81.25%;
  }
  .col-md-14 {
    width: 87.5%;
  }
  .col-md-15 {
    width: 93.75%;
  }
  .col-md-16 {
    width: 100%;
  }
  .col-md-offset-1 {
    margin-left: 6.25%;
  }
  .col-md-offset-2 {
    margin-left: 12.5%;
  }
  .col-md-offset-3 {
    margin-left: 18.75%;
  }
  .col-md-offset-4 {
    margin-left: 25%;
  }
  .col-md-offset-5 {
    margin-left: 31.25%;
  }
  .col-md-offset-6 {
    margin-left: 37.5%;
  }
  .col-md-offset-7 {
    margin-left: 43.75%;
  }
  .col-md-offset-8 {
    margin-left: 50%;
  }
  .col-md-offset-9 {
    margin-left: 56.25%;
  }
  .col-md-offset-10 {
    margin-left: 62.5%;
  }
  .col-md-offset-11 {
    margin-left: 68.75%;
  }
  .col-md-offset-12 {
    margin-left: 75%;
  }
  .col-md-offset-13 {
    margin-left: 81.25%;
  }
  .col-md-offset-14 {
    margin-left: 87.5%;
  }
  .col-md-offset-15 {
    margin-left: 93.75%;
  }
  .col-md-offset-16 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-13,
  .col-lg-14,
  .col-lg-15,
  .col-lg-16,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }
  .col-lg-1 {
    width: 6.25%;
  }
  .col-lg-2 {
    width: 12.5%;
  }
  .col-lg-3 {
    width: 18.75%;
  }
  .col-lg-4 {
    width: 25%;
  }
  .col-lg-5 {
    width: 31.25%;
  }
  .col-lg-6 {
    width: 37.5%;
  }
  .col-lg-7 {
    width: 43.75%;
  }
  .col-lg-8 {
    width: 50%;
  }
  .col-lg-9 {
    width: 56.25%;
  }
  .col-lg-10 {
    width: 62.5%;
  }
  .col-lg-11 {
    width: 68.75%;
  }
  .col-lg-12 {
    width: 75%;
  }
  .col-lg-13 {
    width: 81.25%;
  }
  .col-lg-14 {
    width: 87.5%;
  }
  .col-lg-15 {
    width: 93.75%;
  }
  .col-lg-16 {
    width: 100%;
  }
  .col-lg-offset-1 {
    margin-left: 6.25%;
  }
  .col-lg-offset-2 {
    margin-left: 12.5%;
  }
  .col-lg-offset-3 {
    margin-left: 18.75%;
  }
  .col-lg-offset-4 {
    margin-left: 25%;
  }
  .col-lg-offset-5 {
    margin-left: 31.25%;
  }
  .col-lg-offset-6 {
    margin-left: 37.5%;
  }
  .col-lg-offset-7 {
    margin-left: 43.75%;
  }
  .col-lg-offset-8 {
    margin-left: 50%;
  }
  .col-lg-offset-9 {
    margin-left: 56.25%;
  }
  .col-lg-offset-10 {
    margin-left: 62.5%;
  }
  .col-lg-offset-11 {
    margin-left: 68.75%;
  }
  .col-lg-offset-12 {
    margin-left: 75%;
  }
  .col-lg-offset-13 {
    margin-left: 81.25%;
  }
  .col-lg-offset-14 {
    margin-left: 87.5%;
  }
  .col-lg-offset-15 {
    margin-left: 93.75%;
  }
  .col-lg-offset-16 {
    margin-left: 100%;
  }
}

/*parts*/
/* grid etc */
html {
  font-size: 62.5%;
}

.container {
  width: 960px;
  margin: 0 auto;
}

body,
html {
  width: 100vw;
  background-color: #f2f2f2;
}

.pageHeader {
  background: #ffffff;
  height: 130px;
}

.headerLogo h1 {
  display: inline-block;
}

.headerLogo h1 a {
  display: block;
  width: 90px;
  height: 90px;
  text-indent: -9999px;
  background: url("/dist/img/de_logo.svg") no-repeat;
  background-size: cover;
}

h2.header {
  padding-top: 30px;
  display: inline-block;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

a.menuButton {
  display: block;
  margin-top: 50px;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
  background: url("/dist/img/ico_menu.svg") no-repeat;
  background-size: cover;
  position: relative;
  right: -35px;
}

nav.socialNav ul,
nav.tagNav ul,
nav.topNav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

nav.socialNav ul li,
nav.tagNav ul li,
nav.topNav ul li {
  display: inline-block;
  text-transform: uppercase;
  margin-right: 1em;
}

nav.socialNav ul li a,
nav.tagNav ul li a,
nav.topNav ul li a {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #333333;
  font-size: 1.2rem;
}

nav.socialNav ul li a,
nav.tagNav ul li a {
  font-size: 1.1rem;
  color: #666666;
}

nav.socialNav ul li a.active,
nav.socialNav ul li a:hover,
nav.tagNav ul li a.active,
nav.tagNav ul li a:hover {
  color: #990000;
  text-decoration: line-through;
}

nav.topNav {
  text-align: right;
  padding-top: 50px;
}

nav.topNav a.active {
  color: #000000;
  text-decoration: none;
  font-weight: 700;
}

div.tagNavContainer {
  position: relative;
}

nav.tagNav {
  text-align: right;
  transform: rotate(-90deg) translate(0px, -290px);
  transform-origin: 100% 0;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 9999;
}

nav.socialNav {
  display: block;
  width: 50vh;
  text-align: left;
  transform: rotate(90deg) translate(0, -960px);
  transform-origin: 0 0;
  position: relative;
  top: 44px;
}

section.mainSection {
  padding-top: 20px;
}

section.mainSection h1 {
  margin: 0;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  color: #333333;
  font-weight: 200;
  font-size: 2.8rem;
  text-transform: uppercase;
}

section.mainContent {
  min-height: 650px;
  padding-bottom: 20px;
  padding-top: 35px;
}

article.workItem {
  position: relative;
  width: 100%;
  height: 250px;
}

article.workItem div.workPicture {
  position: absolute;
  width: 395px;
  height: 395px;
  overflow: hidden;
}

article.workItem div.workPicture a.workPreviewLink {
  display: block;
  width: 395px;
  height: 395px;
}

article.workItem div.workPicture img {
  width: 395px;
  height: 395px;
}

article.workItem div.workPicture a.arrow {
  position: absolute;
  top: 10px;
  display: block;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 19px;
}

article.workItem div.workPicture.left {
  left: 0;
}

article.workItem div.workPicture.left a.arrow {
  left: 10px;
  background: url("/dist/img/freccia_dx.svg") no-repeat;
  background-size: cover;
}

article.workItem div.workPicture.right {
  right: 0;
}

article.workItem div.workPicture.right a.arrow {
  right: 10px;
  background: url("/dist/img/freccia_sx.svg") no-repeat;
  background-size: cover;
}

article.workItem a.workTitle {
  color: #ffffff;
}

article.workItem a.workTitle h1 {
  color: #ffffff;
  display: block;
  position: absolute;
  z-index: 9999;
  top: calc(85%-6.4rem);
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: "Tinos", Georgia, Times, serif;
  font-weight: 400;
  font-size: 6.4rem;
  text-transform: capitalize;
}

article.workItem a.workTitle h1.last {
  top: calc(55%-6.4rem);
}

article.workItem:last-child {
  height: 395px;
}

article.workItem:last-child h1 {
  top: calc(50%-5.4rem);
}

section.mainSection.workHeader {
  position: relative;
}

section.mainSection.workHeader h1 {
  color: #ffffff;
  display: block;
  position: absolute;
  top: 20px;
  left: 12.5%;
  z-index: 9999;
  width: 87.5%;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: "Tinos", Georgia, Times, serif;
  font-weight: 400;
  font-size: 8rem;
  text-transform: capitalize;
  /*  text-shadow: 1px 1px 5px rgba(0,0,0,.5); */
}

section.mainSection.workHeader h2 {
  margin: 0;
  padding: 0;
  margin-top: calc(9rem+20px);
  font-family: "Tinos", Georgia, Times, serif;
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  font-size: 1.6rem;
}

section.mainSection.workHeader h3.projectTags {
  margin: 1em 1em 0 0;
  padding: 0;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 1.4rem;
  display: inline-block;
}

section.mainSection.workHeader h3.projectTags a {
  color: #000000;
}

section.mainSection.workHeader p.projectDescription {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
}

section.mainSection.workPhotos {
  margin-top: 0;
}

section.mainSection.workPhotos div.row {
  margin-top: 20px;
}

body.about nav.tagNav {
  display: none;
}

body.about section.mainSection {
  padding: 35px 0 10px 0;
}

body.about section.mainSection.aboutHeader {
  padding-top: 0;
}

body.about section.mainSection.aboutHeader h2 {
  margin: 0;
  padding: 0;
  font-size: 8rem;
  font-family: "Tinos", Georgia, Times, serif;
  font-weight: 400;
  color: #000000;
}

body.about section.mainSection.aboutHeader h2 span.de {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 800;
}

body.about section.mainSection.aboutHeader a.rightArrow {
  display: block;
  margin-top: 65px;
}

body.about section.mainSection.aboutHeader p.aboutIntro {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
}

body.about div.brandList {
  text-align: center;
}

body.about div.brandList img {
  margin: 10px;
}

body.about nav.aboutNav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

body.about nav.aboutNav ul li {
  padding-bottom: 1.4em;
}

body.about nav.aboutNav ul li a {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.075em;
  font-weight: 700;
  color: #bebebe;
  text-transform: uppercase;
}

body.about nav.aboutNav ul li a.active,
body.about nav.aboutNav ul li a:hover {
  color: #000000;
}

body.about p.aboutSection {
  background: url("/dist/img/about_arrow.png") no-repeat;
  background-position: 0 0;
  margin: 0 0 0 -25px;
  padding-left: 25px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
}

div.aboutLollo h4,
div.aboutLollo h5 {
  margin: 0;
  padding: 0;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
}

div.aboutLollo h4 {
  font-weight: 800;
  text-transform: uppercase;
}

div.aboutLollo h5 {
  font-weight: 400;
}

div.aboutLollo p {
  font-family: "Tinos", Georgia, Times, serif;
  font-weight: 300;
  font-size: 1.6rem;
}

#detail_1 {
  margin-top: 4.2rem;
}

#detail_2 {
  margin-top: 8.3rem;
}

#detail_3 {
  margin-top: 12.4rem;
}

#detail_4 {
  margin-top: 16.5rem;
}

#detail_5 {
  margin-top: 20.6rem;
}

body.contact {
  background: #1d1d1b;
}

body.contact section.mainSection {
  min-height: calc(100vh-250px);
  padding: 35px 0 20px 0;
}

body.contact section.mainSection h1,
body.contact section.mainSection p {
  color: #bebebe;
}

body.contact nav.tagNav {
  display: none;
}

body.contact nav.socialNav ul li a {
  color: #bebebe;
}

body.contact form#contactForm {
  padding-top: 10px;
}

body.contact form#contactForm div.row {
  margin-bottom: 3rem;
  padding-left: 5px;
  padding-right: 5px;
}

body.contact form#contactForm input,
body.contact form#contactForm textarea {
  width: 100%;
  padding: 5px;
  color: #f2f2f2;
  font-size: 1.2rem;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  background: #1d1d1b;
  border: none;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

body.contact form#contactForm input:placeholder,
body.contact form#contactForm textarea:placeholder {
  color: #f2f2f2;
}

body.contact form#contactForm input.error,
body.contact form#contactForm textarea.error {
  border: 1px solid #990000;
}

body.contact form#contactForm a#send {
  padding: 3px 3px 3px 20px;
  color: #ffffff;
  font-family: "Tinos", Georgia, Times, serif;
  font-size: 2.4rem;
  cursor: pointer;
}

body.contact form#contactForm a#send:hover {
  background: #333333;
}

body.contact div#formFeedback {
  display: none;
}

footer.pageFooter {
  background: #ffffff;
  height: 120px;
}

footer.pageFooter div.aiapLogo {
  padding-top: 25px;
}

footer.pageFooter div.aiapLogo a {
  display: block;
  text-indent: -9999px;
  width: 210px;
  height: 71px;
  background: url("/dist/img/aiap.png") no-repeat;
  background-size: cover;
}

footer.pageFooter div.footerLinks {
  padding-top: 50px;
  text-align: right;
}

footer.pageFooter div.footerLinks a {
  color: #333333;
  text-transform: uppercase;
  font-size: 1.2rem;
}
