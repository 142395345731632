/*consts*/
$white: #ffffff;
$lightgray: #f2f2f2;
$midgray: #bebebe;
$black: #000000;
$medgray: #666666;
$darkgray: #333333;
$darkergray: #1d1d1b;
$darkred: #990000;
$ral: 'Raleway',Helvetica,Arial,sans-serif;
$tin: 'Tinos',Georgia,Times,serif;
@import 'reset';
@import 'grid';
/*parts*/
@import 'fonts';
@import 'general';
@import 'about';
@import 'contact';
@import 'footer';
