/* GRID SYSTEM */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width:768px) {
  .container {
    width: 750px;
  }
}
@media (min-width:992px) {
  .container {
    width: 970px;
  }
}
@media (min-width:1200px) {
  .container {
    width: 1170px;
  }
}

.pull-right {
  float: right!important;
}

.pull-left {
  float: left!important;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
  clear: both;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-13,
.col-lg-14,
.col-lg-15,
.col-lg-16,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-13,
.col-md-14,
.col-md-15,
.col-md-16,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-13,
.col-sm-14,
.col-sm-15,
.col-sm-16,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-13,
.col-xs-14,
.col-xs-15,
.col-xs-16,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-13,
.col-xs-14,
.col-xs-15,
.col-xs-16,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.col-xs-1 {
  width: 6.25%;
}

.col-xs-2 {
  width: 12.5%;
}

.col-xs-3 {
  width: 18.75%;
}

.col-xs-4 {
  width: 25%;
}

.col-xs-5 {
  width: 31.25%;
}

.col-xs-6 {
  width: 37.5%;
}

.col-xs-7 {
  width: 43.75%;
}

.col-xs-8 {
  width: 50%;
}

.col-xs-9 {
  width: 56.25%;
}

.col-xs-10 {
  width: 62.5%;
}

.col-xs-11 {
  width: 68.75%;
}

.col-xs-12 {
  width: 75%;
}

.col-xs-13 {
  width: 81.25%;
}

.col-xs-14 {
  width: 87.5%;
}

.col-xs-15 {
  width: 93.75%;
}

.col-xs-16 {
  width: 100%;
}

.col-xs-offset-1 {
  margin-left: 6.25%;
}

.col-xs-offset-2 {
  margin-left: 12.5%;
}

.col-xs-offset-3 {
  margin-left: 18.75%;
}

.col-xs-offset-4 {
  margin-left: 25%;
}

.col-xs-offset-5 {
  margin-left: 31.25%;
}

.col-xs-offset-6 {
  margin-left: 37.5%;
}

.col-xs-offset-7 {
  margin-left: 43.75%;
}

.col-xs-offset-8 {
  margin-left: 50%;
}

.col-xs-offset-9 {
  margin-left: 56.25%;
}

.col-xs-offset-10 {
  margin-left: 62.5%;
}

.col-xs-offset-11 {
  margin-left: 68.75%;
}

.col-xs-offset-12 {
  margin-left: 75%;
}

.col-xs-offset-13 {
  margin-left: 81.25%;
}

.col-xs-offset-14 {
  margin-left: 87.5%;
}

.col-xs-offset-15 {
  margin-left: 93.75%;
}

.col-xs-offset-16 {
  margin-left: 100%;
}
@media (min-width:768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-13,
  .col-sm-14,
  .col-sm-15,
  .col-sm-16,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }

  .col-sm-1 {
    width: 6.25%;
  }

  .col-sm-2 {
    width: 12.5%;
  }

  .col-sm-3 {
    width: 18.75%;
  }

  .col-sm-4 {
    width: 25%;
  }

  .col-sm-5 {
    width: 31.25%;
  }

  .col-sm-6 {
    width: 37.5%;
  }

  .col-sm-7 {
    width: 43.75%;
  }

  .col-sm-8 {
    width: 50%;
  }

  .col-sm-9 {
    width: 56.25%;
  }

  .col-sm-10 {
    width: 62.5%;
  }

  .col-sm-11 {
    width: 68.75%;
  }

  .col-sm-12 {
    width: 75%;
  }

  .col-sm-13 {
    width: 81.25%;
  }

  .col-sm-14 {
    width: 87.5%;
  }

  .col-sm-15 {
    width: 93.75%;
  }

  .col-sm-16 {
    width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 6.25%;
  }

  .col-sm-offset-2 {
    margin-left: 12.5%;
  }

  .col-sm-offset-3 {
    margin-left: 18.75%;
  }

  .col-sm-offset-4 {
    margin-left: 25%;
  }

  .col-sm-offset-5 {
    margin-left: 31.25%;
  }

  .col-sm-offset-6 {
    margin-left: 37.5%;
  }

  .col-sm-offset-7 {
    margin-left: 43.75%;
  }

  .col-sm-offset-8 {
    margin-left: 50%;
  }

  .col-sm-offset-9 {
    margin-left: 56.25%;
  }

  .col-sm-offset-10 {
    margin-left: 62.5%;
  }

  .col-sm-offset-11 {
    margin-left: 68.75%;
  }

  .col-sm-offset-12 {
    margin-left: 75%;
  }

  .col-sm-offset-13 {
    margin-left: 81.25%;
  }

  .col-sm-offset-14 {
    margin-left: 87.5%;
  }

  .col-sm-offset-15 {
    margin-left: 93.75%;
  }

  .col-sm-offset-16 {
    margin-left: 100%;
  }
}
@media (min-width:992px) {
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-13,
  .col-md-14,
  .col-md-15,
  .col-md-16,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }

  .col-md-1 {
    width: 6.25%;
  }

  .col-md-2 {
    width: 12.5%;
  }

  .col-md-3 {
    width: 18.75%;
  }

  .col-md-4 {
    width: 25%;
  }

  .col-md-5 {
    width: 31.25%;
  }

  .col-md-6 {
    width: 37.5%;
  }

  .col-md-7 {
    width: 43.75%;
  }

  .col-md-8 {
    width: 50%;
  }

  .col-md-9 {
    width: 56.25%;
  }

  .col-md-10 {
    width: 62.5%;
  }

  .col-md-11 {
    width: 68.75%;
  }

  .col-md-12 {
    width: 75%;
  }

  .col-md-13 {
    width: 81.25%;
  }

  .col-md-14 {
    width: 87.5%;
  }

  .col-md-15 {
    width: 93.75%;
  }

  .col-md-16 {
    width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 6.25%;
  }

  .col-md-offset-2 {
    margin-left: 12.5%;
  }

  .col-md-offset-3 {
    margin-left: 18.75%;
  }

  .col-md-offset-4 {
    margin-left: 25%;
  }

  .col-md-offset-5 {
    margin-left: 31.25%;
  }

  .col-md-offset-6 {
    margin-left: 37.5%;
  }

  .col-md-offset-7 {
    margin-left: 43.75%;
  }

  .col-md-offset-8 {
    margin-left: 50%;
  }

  .col-md-offset-9 {
    margin-left: 56.25%;
  }

  .col-md-offset-10 {
    margin-left: 62.5%;
  }

  .col-md-offset-11 {
    margin-left: 68.75%;
  }

  .col-md-offset-12 {
    margin-left: 75%;
  }

  .col-md-offset-13 {
    margin-left: 81.25%;
  }

  .col-md-offset-14 {
    margin-left: 87.5%;
  }

  .col-md-offset-15 {
    margin-left: 93.75%;
  }

  .col-md-offset-16 {
    margin-left: 100%;
  }
}
@media (min-width:1200px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-13,
  .col-lg-14,
  .col-lg-15,
  .col-lg-16,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }

  .col-lg-1 {
    width: 6.25%;
  }

  .col-lg-2 {
    width: 12.5%;
  }

  .col-lg-3 {
    width: 18.75%;
  }

  .col-lg-4 {
    width: 25%;
  }

  .col-lg-5 {
    width: 31.25%;
  }

  .col-lg-6 {
    width: 37.5%;
  }

  .col-lg-7 {
    width: 43.75%;
  }

  .col-lg-8 {
    width: 50%;
  }

  .col-lg-9 {
    width: 56.25%;
  }

  .col-lg-10 {
    width: 62.5%;
  }

  .col-lg-11 {
    width: 68.75%;
  }

  .col-lg-12 {
    width: 75%;
  }

  .col-lg-13 {
    width: 81.25%;
  }

  .col-lg-14 {
    width: 87.5%;
  }

  .col-lg-15 {
    width: 93.75%;
  }

  .col-lg-16 {
    width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 6.25%;
  }

  .col-lg-offset-2 {
    margin-left: 12.5%;
  }

  .col-lg-offset-3 {
    margin-left: 18.75%;
  }

  .col-lg-offset-4 {
    margin-left: 25%;
  }

  .col-lg-offset-5 {
    margin-left: 31.25%;
  }

  .col-lg-offset-6 {
    margin-left: 37.5%;
  }

  .col-lg-offset-7 {
    margin-left: 43.75%;
  }

  .col-lg-offset-8 {
    margin-left: 50%;
  }

  .col-lg-offset-9 {
    margin-left: 56.25%;
  }

  .col-lg-offset-10 {
    margin-left: 62.5%;
  }

  .col-lg-offset-11 {
    margin-left: 68.75%;
  }

  .col-lg-offset-12 {
    margin-left: 75%;
  }

  .col-lg-offset-13 {
    margin-left: 81.25%;
  }

  .col-lg-offset-14 {
    margin-left: 87.5%;
  }

  .col-lg-offset-15 {
    margin-left: 93.75%;
  }

  .col-lg-offset-16 {
    margin-left: 100%;
  }
}
