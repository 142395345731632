body.contact {
  background: $darkergray;

  section.mainSection {
    min-height: calc(100vh-250px;);
    padding: 35px 0 20px 0;


    h1,
    p {
      color: $midgray;
    }
  }

  nav.tagNav {
    display: none;
  }

  nav.socialNav ul li a {
    color: $midgray;
  }

  form#contactForm {
    padding-top:10px;
    div.row {
      margin-bottom: 3rem;
      padding-left: 5px;
      padding-right: 5px;
    }

    input,
    textarea {
      width: 100%;
      padding: 5px;
      color: $lightgray;
      font-size: 1.2rem;
      font-family: $ral;
      text-transform: uppercase;
      background: $darkergray;
      border: none;
      border-top: 1px solid $lightgray;
      border-bottom: 1px solid $lightgray;
    }

    input:placeholder,
    textarea:placeholder {
      color: $lightgray;
    }

    input.error,
    textarea.error {
      border: 1px solid $darkred;
    }

    a#send {
      padding: 3px 3px 3px 20px;
      color: $white;
      font-family: $tin;
      font-size: 2.4rem;
      cursor: pointer;
    }

    a#send:hover {
      background: $darkgray;
    }
  }

  div#formFeedback {
    display: none;
  }
}
