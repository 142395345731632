footer.pageFooter {
  background: $white;
  height: 120px;

  div.aiapLogo {
    padding-top: 25px;

    a {
      display: block;
      text-indent: -9999px;
      width: 210px;
      height: 71px;
      background: url("/dist/img/aiap.png") no-repeat;
      background-size: cover;
    }
  }

  div.footerLinks {
    padding-top: 50px;
    text-align: right;

    a {
      color: $darkgray;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
}
